
import axios from 'axios';

export const getChapterGroupsApi = async (authtoken, db) => {
  // console.log('===>getChapterGroupsApi authtoken:', authtoken);
  const axiosInstance = axios.create({
    withCredentials: true
  });
  return await axiosInstance.get(process.env.REACT_APP_API + `/get/chapter/groups?db=${db}`, {
    headers: {
      authtoken
    }
  });
};

export const updateChapterGroupsApi = async (authtoken, db, chapterGroup ) => {
    // console.log('===>getChapterGroupsApi authtoken:', authtoken);
    const axiosInstance = axios.create({
      withCredentials: true
    });
    return await axiosInstance.post(process.env.REACT_APP_API + '/update/chapter/group', 
    { db, chapterGroup },
    {
      headers: {
        authtoken
      }
    });
  };

  export const getChaptersApi = async (authtoken, db) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    return await axiosInstance.get(process.env.REACT_APP_API + `/get/chapters?db=${db}`, {
      headers: {
        authtoken
      }
    });
  };

  export const updateOrgApi = async (authtoken, db, org ) => {
    // console.log('===>getChapterGroupsApi authtoken:', authtoken);
    const axiosInstance = axios.create({
      withCredentials: true
    });
    return await axiosInstance.post(process.env.REACT_APP_API + '/organization/update', 
    { db, org },
    {
      headers: {
        authtoken
      }
    });
  };

  export const getMenuContent = async ( authtoken, db, orgId ) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    return await axiosInstance.get(process.env.REACT_APP_API + `/get/menu/content?db=${db}&orgId=${orgId}`, {
      headers: {
        authtoken
      }
    });
  }