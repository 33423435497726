import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './styles/app.css';
import './styles/index.css';
import './styles/header.css';
import './styles/login.css';
import './styles/base.css';
import './styles/database.css';
import './styles/assets.css';

import App from './App';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
