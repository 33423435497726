import React, { useEffect ,useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';

import {
    fetchOrganizationsApi,
  } from '../apis/admin.api';
import { getChaptersApi, 
    getChapterGroupsApi, 
    updateChapterGroupsApi,
    updateOrgApi,
    getMenuContent } from '../apis/db.api';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    overlay: {zIndex: 1000}
  };
//   Modal.setAppElement('#yourAppElement');

const Assets = () => {
    const [ selectedDatabase, setSelectedDatabase ] = useState(null);
    const [ selectedDb, setSelectedDb ] = useState('dev');
    const [ organizationArray, setOrganizationArray ] = useState([]);
    const [ organizations, setOrganizations ] = useState([]);
    const [ selectedOrg, setSelectedOrg ] = useState(null);
    const [ menuContent, setMenuContent ] = useState({});
    const [ menuChapterContent, setMenuChapterContent ] = useState([]);
    
    const [ chapters, setChapters ] = useState([]);
    const [ chapterGroups, setChapterGroups ] = useState([]);
    const [ chapterGroup, setChapterGroup ] = useState([]);
    const [ chapterGroupName, setChapterGroupName ] = useState('');
    const [ chapterInclude, setChapterInclude ] = useState([]);
    const [ chapterOcclude, setChapterOcclude ] = useState([]);
    const { loginStatus  } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line
    const { idToken } = useSelector((state) => state.user.user.idToken, shallowEqual);
    const { userName } = useSelector((state) => state.user.user.name, shallowEqual);
    
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
      }
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
      }
    function closeModal() {
        setIsOpen(false);
        let currentGroups = chapterGroups;
        currentGroups.unshift({value: [], label: chapterGroupName});
        console.log('updated currentGroups:', currentGroups);
        setChapterGroups(currentGroups);
      }

    useEffect(() => {
      getChaptersFromDb(); 
    }, [ idToken, selectedDb ])

    const fetchGroups = async () => {
        let groups = [];
        groups = await getChapterGroupsApi('123456', 'dev');
        console.log('----> CHAPTER GROUPS:', groups.data)
        const options = groups.data.map((g) => {
          return { value: g.chapters, label: g.name }
        })
        options.push({ value: [], label: 'create new' });
        console.log('---> setChapterGroups:', options)
        setChapterGroups(options);
      }

    useEffect(() => {
        console.log('----> GETTING CHAPTER GROUPS!!!')    
        fetchGroups();
        fetchOrganizations();
      }, [idToken, selectedDb])

    const fetchOrganizations = async () => {
        try {
          const orgRes = await fetchOrganizationsApi(idToken);
          console.log('========> orgArr:', orgRes.data);
          setOrganizationArray(orgRes.data);
          let orgs = orgRes.data.map((o) => {
            return {value: o._id, label: o.name}
          })
          setOrganizations(orgs);
        } catch (err) {
          console.log(err);
        }
      };

    const getChaptersFromDb = async () => {
      const chaptersRes = await getChaptersApi(idToken, selectedDb);
      console.log('---> dbChapters:', chaptersRes)
      const options = chaptersRes.data.map((c) => {
        return { value: c._id, label: c.title }
      })
      setChapters(options);
      return chaptersRes.data;
    }

    const updateChapterGroup = (option) => {
      setChapterGroup(option);
    }

    const addChapterGroup = async () => {
        console.log('userName:', userName)
        const chapterIds = chapterGroup.map((c) => {
            return c.value;
        })
        const newChapterGroup = {
            name: chapterGroupName,
            chapters: chapterIds,
            maintainer: userName
        }
        const response = await updateChapterGroupsApi(idToken, 'dev', newChapterGroup);
        console.log('---> addChapterGroup:', response.data)
        fetchGroups();
    }

    const selectChapterGroupName = (option) => {
        console.log('===> selectChapterGroupName:', option)
        if (option.label === 'create new') {
            openModal();
        } else {
            setChapterGroupName(option.label);
            console.log('-------> updateChapterGroup',chapterGroupName)
            let rebuiltChapterGroup = chapters.map((c) => {
                if (option.value.includes(c.value)) {
                    return  c;
                }
            })
            const filtered = rebuiltChapterGroup.filter((elements) => {
                return elements !== null && elements !== undefined;
              });
            console.log('REBUILT chapterGroup:', filtered)
            updateChapterGroup(filtered)
        // updateChapterGroup(option);
        }
    }

    const selectOrg = async (option) => { 
        let obj = organizationArray.find(o => o._id === option.value);
        console.log('Selected ORG:', option, obj)
        setSelectedOrg(obj);
        const dbMenuContent = await getMenuContent(idToken, selectedDb, obj.name);
        let menuChapters = dbMenuContent.data.chapters;
        menuChapters && menuChapters.sort(function(a, b) {
            if (a.chapterIndex < b.chapterIndex) return -1;
            if (a.chapterIndex > b.chapterIndex) return 1;
            return 0;
          });
        // moduleContent needs to be an OBJ with key of chapterIndex
        // then sort each chapter by moduleIndex
        // TBD: ---> move to dbmaster API call
        let moduleContent = {}; // [...menuChapters.lessons, ...menuChapters.quizzes, ...menuChapters.negotiations];
        menuChapters.forEach((c, i) => {
            if (c.lessons) {
                if (!moduleContent[c.chapterIndex]) moduleContent[c.chapterIndex] = [];
                moduleContent[c.chapterIndex] = [...moduleContent[c.chapterIndex], ...c.lessons];
            }
            if (c.quizzes) {
                if (!moduleContent[c.chapterIndex]) moduleContent[c.chapterIndex] = [];
                moduleContent[c.chapterIndex] = [...moduleContent[c.chapterIndex], ...c.quizzes]
            }
            if (c.negotiations) {
                if (!moduleContent[c.chapterIndex]) moduleContent[c.chapterIndex] = [];
                moduleContent[c.chapterIndex] = [...moduleContent[c.chapterIndex], ...c.negotiations]
            }
        })
        const moduleContentKeys = Object.keys(moduleContent);
        moduleContentKeys.forEach((k) => {
            moduleContent[k] && moduleContent[k].sort(function(a, b) {
                if (a.moduleIndex < b.moduleIndex) return -1;
                if (a.moduleIndex > b.moduleIndex) return 1;
                return 0;
              });
        })
        
        console.log('===========> menuContent:', dbMenuContent.data);
        console.log('=======> menuChapters:', menuChapters);
        console.log('===> moduleContent:', moduleContent);
        setMenuChapterContent(menuChapters);
        setMenuContent(dbMenuContent.data);
    }
    const handleSubmit = () => {
        console.log('submit')
    }
    const orgSettingsForm = () => {
        return (
          <form className="org-settings-form" onSubmit={handleSubmit}>
            <input
              type="text"
              className="org-form-control"
              value={chapterGroupName}
              onChange={(e) => console.log(e.target.value)}
              placeholder="Chapter Group"
              autoComplete="off"
            />
          </form>
        );
      };

    const updateChapterInclude = async (option) => {
        // console.log('updateChapterInclude: ', option)
        setChapterInclude(option);
        let update = {...selectedOrg};
        const includes = option.map((o) => {
            return o.value;
        })
        update.chapterInclude = includes;
        setSelectedOrg(update);
        const response = await updateOrgApi(idToken, selectedDb, update);
        fetchOrganizations();
    }
    const updateChapterOcclude = async (option) => {
        // console.log('=========>> updateChapterOcclude: ', option)
        setChapterOcclude(option);
        let update = {...selectedOrg};
        const occludes = option.map((o) => {
            return o.value;
        })
        update.chapterOcclude = occludes;
        setSelectedOrg(update);
        const response = await updateOrgApi(idToken, selectedDb, update);
        fetchOrganizations();
    }
    const updateSelectedOrgChapterGroup = async (option) => {
        let update = selectedOrg;
        update.chapterGroup = option.label;
        setSelectedOrg(update);
        const response = await updateOrgApi(idToken, selectedDb, update);
        // console.log('--> updateSelectedOrgChapterGroup response:', response);
        fetchOrganizations();
    }
    
    return (
        <div className="app-database-page">
        <div className="administrator-page">
          <div className="administrator-page-grid">
            <div className="administrator-page-sect-1">
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Enter a unique name:</h2>
                <div>Create Chapter Group</div>
                <form>
                <input type='text' onChange={(e) => setChapterGroupName(e.target.value)}/>
                <button onClick={closeModal}>Done</button>
                </form>
            </Modal>
              <div className="administrator-page-header">
                <div className="administrator-page-label">
                  Organization Tools
                </div>
              </div>
            </div>

            <div className="assets-page-section-1-left">
              <div className="assets-section-1-left-grid">
                <div className="administrator-section-1-left-grid-row-1">
                  Settings {selectedOrg && JSON.stringify(selectedOrg.chapterGroup)}
                </div>
                <div className="assets-section-1-left-grid-row-2to5">
                {true && <Select maxMenuHeight={100} options={organizations} onChange={(option) => selectOrg(option)} />}
                Select Default Chapter Group
                {true && <Select placeholder={selectedOrg && selectedOrg.chapterGroup} value={selectedOrg && selectedOrg.chapterGroup} maxMenuHeight={100} options={chapterGroups.slice(0, -1)} onChange={(option) => updateSelectedOrgChapterGroup(option)} />}
                Update Include Option
                {true && <Select value={chapterInclude} isMulti maxMenuHeight={200} options={chapters} onChange={(option) => updateChapterInclude(option)} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />} 
                Update Occlude Option
                {true && <Select value={chapterOcclude} isMulti maxMenuHeight={200} options={chapters} onChange={(option) => updateChapterOcclude(option)} styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} />}
                </div>
                <div className="administrator-section-1-left-grid-row-5">
                </div>
              </div>
            </div>

            <div className="assets-page-section-1-mid">
              {false && JSON.stringify(menuChapterContent)}
              <ul>
              {menuChapterContent.map((c) => {
                return <li key={c._id} >
                    Chapter: {c.title}
                    <ul>
                        {c.lessons.map((l)=>{
                            return <li key={l._id}>Lesson: {l.title}</li>
                        })}
                    </ul>
                    <ul>
                        {c.quizzes.map((q)=>{
                            return <li key={q._id}>Quiz: {q.title}</li>
                        })}
                    </ul>
                </li>
              })}
              </ul>
              
            </div>

            

            {true && <div className="database-page-sect-3">
              <div className="administrator-page-header">
                <div className="administrator-page-label">
                Create Chapter Groups
                </div>
              </div>
            </div>}
            {true && <div className="database-page-section-3-left">
              <div className="administrator-section-1-left-grid">
                <div className="database-section-3-left-grid-row-1">
                  Select Chapters
                </div>
                <div className="database-section-1-left-grid-row-2to5">
                  {false && chapters && chapters.map((c) => {
                    return <div key={c._id}>{c.title}</div>
                  })}
                  
                  {true && <Select value={chapterGroup} isMulti menuIsOpen={true} maxMenuHeight={100} options={chapters} onChange={(option) => updateChapterGroup(option)} />}
                </div>
                {false && <div className="database-section-1-left-grid-row-5">
                  <Select options={[{label: 'development', value: 'dev'}, {label: 'staging', value: 'stage'}, {label: 'production', value: 'prod-I'}]}/>
                  <button onClick={() => console.log('click')}>Load Chapters</button>
                </div>}
              </div>
            </div>}

            {true && <div className="database-page-section-3-right">
              {(
                <div className="administrator-section-1-right-grid">
                  <div className="database-section-1-right-grid-row-1">
                    Create or Update Chapter Groups {chapterGroupName}
                  </div>
                  
                  <div className="database-section-1-right-grid-row-2to5">
                    Enter a name for the Chapter Group:
                  {false && <input
                    type="text"
                    placeholder="Chapter Group Name"
                    onChange={(e) => setChapterGroupName(e.target.value)}
                  />}
                  {true && <Select options={chapterGroups} onChange={(option) => selectChapterGroupName(option)} />}
                  Included Chapters: 
                  {true && <ul style={{ height: '150px', overflowY: 'true' }}>
                    {true && chapterGroup && chapterGroup.map((g) => {
                        return <li key={g.value}>{g.label}</li>
                    })}
                    </ul>}
                  </div>
                  <div className="database-section-1-right-grid-row-5">
                    <button onClick={addChapterGroup}>Create or Update Chapter Group</button>
                  </div>
                </div>
              )}
            </div>}

            {true && <div className="administrator-page-sect-5">
              <div className="administrator-page-header">
                <div className="administrator-page-label">App Settings</div>
              </div>
            </div>}
            {false && <div className="administrator-page-section-5-left">
              <div className="administrator-section-1-left-grid">
                <div className="administrator-section-1-left-grid-row-1">
                  Select a Database
                </div>
                <div className="administrator-section-1-left-grid-row-2to5">
                  
                </div>
                <div className="administrator-section-1-left-grid-row-5">
                  <button onClick={()=>console.log("click")}>Get Database</button>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    );
};

export default Assets;

// {organizationArray &&
//     organizationArray.length > 0 &&
//     organizationArray.map((o) => {
//       return <li key={o.name}>{o.name}</li>;
//     })}

/*

<div className="administrator-page-section-1-right">
              <div className="administrator-section-1-right-grid">
                <div className="administrator-section-1-right-grid-row-1">
                  Database B
                </div>
                <div className="administrator-section-1-right-grid-row-2to5">
                <select
                    defaultValue={selectedDatabase}
                    onChange={(e) => setSelectedDatabase(e.target.value)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                  </select>
                </div>
                <div className="administrator-section-1-right-grid-row-5">
                  <button onClick={() => console.log('click')}>Select Database B</button>
                </div>
              </div>
            </div>

            <div className="administrator-page-section-1-far-right">
              <ul>
                <li>User Cache:</li>
                
              </ul>
            </div>

*/