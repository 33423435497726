import React, { useEffect, useState } from 'react';
import { MailOutlined, GoogleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'antd';

// FB
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification
} from 'firebase/auth';
import { auth, googleAuthProvider } from '../config/fb';
// REDUX
import { setCurrentPage, setPrivacy, setAppVerbosity } from '../redux/app.slice';
/* eslint-disable */
import {
  setFbUser,
  setUser,
  setName,
  setOrganization,
  setRole,
  setLoginStatus,
  setIdToken
} from "../redux/user.slice"; 
/* eslint-enable */
// APIs
import { setCurrentUser, reloadSession } from '../apis/user.api';
import { fetchAppVerbosityApi, updateApplicationApi } from '../apis/app.api';
// Images
import Logo from '../images/lavenir-logo.png';

const Login = () => {
  console.log('Login RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  // REFACTOR to avoid returning new references and force renders
  // https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization
  // let { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    dispatch(setCurrentPage('login'));
  }, []);

  const googleLogin = async () => {
    try {
      signInWithPopup(auth, googleAuthProvider)
        .then(async (userCredential) => {
          // console.log('---------> userCredential:', userCredential);
          let user = userCredential.user;
          const idTokenResult = await user.getIdTokenResult();
          user.idToken = idTokenResult.token;
          // console.log('--------> idTokenResult.token:', idTokenResult.token);
          const dbUser = await setCurrentUser(
            idTokenResult.token,
            JSON.stringify(user),
            user.email
          );

          if (dbUser.data.emailVerified === true) {
            // console.log('LOGIN -> dbUser:', dbUser);
            try {
              await reloadSession().then(async (res) => {
                user = res.data;
                // console.log('=====> LOGIN RELOAD SESSION USER:', user);
                const verbosity = await fetchAppVerbosityApi(idTokenResult.token);
                if (verbosity) {
                  await dispatch(setAppVerbosity(verbosity.data));
                }
                if (res.data.error) {
                  // console.log('LOGIN RELOAD SESSON ERROR:', res.data.error)
                } else {
                  await dispatch(setIdToken(idTokenResult.token));
                  await dispatch(setUser(JSON.stringify(user)));
                  // await dispatch(setFbUser(JSON.stringify(user)));
                  await dispatch(setLoginStatus(true));
                  await dispatch(setName(user.name));
                  await dispatch(setRole(user.role));
                  await dispatch(setOrganization(user.organization));
                  await dispatch(setPrivacy(user.organization.privacy));
                  // console.log('LOGIN -> RELOAD USER SESSION DATA:', res.data)
                  const application = {
                    userId: dbUser.email,
                    webUiRevision: process.env.REACT_APP_WEBUI_VERSION,
                    unityAppRevision: process.env.REACT_APP_WEBGL_BUILD_ID_BR,
                    verbosity: 0,
                    reactVerbosity: 0,
                    privacy: 0,
                    mode: process.env.REACT_APP_MODE,
                    loginStatus: true,
                    lastLoggedIn: new Date()
                  };
                  await updateApplicationApi(idTokenResult.token, application);
                }
              });
            } catch (err) {
              // console.log('LOGIN -> RELOAD USER SESSION ERR:', err)
            }

            // console.log('dbUser:', dbUser, dbUser.data.role);
            navigate('/');
          } else {
            toast.error('You must verify your email, access denied!');
            sendEmailVerification(auth.currentUser).then(() => {
              // console.log('VERIFICATION EAMAIL SENT')
              navigate('/');
            });
            navigate('/');
          }
        })
        .catch((error) => {
          // console.log('LOGIN ERROR', error.message, error.code);
          toast.error(error.message);
          navigate('/login');
        });
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let user = null;
    // TBD: see  'enable email enumeration protection'
    try {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // console.log('LOGIN ==========> userCredential:', userCredential);
          user = userCredential.user;
          const idTokenResult = await user.getIdTokenResult();
          user.idToken = idTokenResult.token;
          // console.log('--------> idTokenResult.token, user, user.email:', idTokenResult.token, user, user.email)
          const dbUser = await setCurrentUser(
            idTokenResult.token,
            JSON.stringify(user),
            user.email
          );

          if (user.emailVerified === true || dbUser.emailVerified === true) {
            const verbosity = await fetchAppVerbosityApi(idTokenResult.token);
            if (verbosity) {
              await dispatch(setAppVerbosity(verbosity.data));
            }
            // console.log('=========> LOGIN -> dbUser:', dbUser);
            try {
              await reloadSession().then(async (res) => {
                user = res.data;
                // console.log('=====> LOGIN RELOAD SESSION USER:', user);
                if (res.data.error) {
                  // console.log('LOGIN RELOAD SESSON ERROR:', res.data.error)
                } else {
                  await dispatch(setIdToken(idTokenResult.token));
                  await dispatch(setUser(JSON.stringify(user)));
                  // await dispatch(setFbUser(JSON.stringify(user)));
                  await dispatch(setLoginStatus(true));
                  await dispatch(setName(user.name));
                  await dispatch(setRole(user.role));
                  await dispatch(setOrganization(user.organization.name));
                  await dispatch(setPrivacy(user.organization.privacy));
                  // console.log('LOGIN -> RELOAD USER SESSION DATA:', res.data)
                  const application = {
                    userId: dbUser.email,
                    webUiRevision: process.env.REACT_APP_WEBUI_VERSION,
                    unityAppRevision: process.env.REACT_APP_WEBGL_BUILD_ID_BR,
                    verbosity: 0,
                    reactVerbosity: 0,
                    privacy: 0,
                    mode: process.env.REACT_APP_MODE,
                    loginStatus: true,
                    lastLoggedIn: new Date()
                  };
                  await updateApplicationApi(idTokenResult.token, application);
                }
              });
            } catch (err) {
              // console.log('LOGIN -> RELOAD USER SESSION ERR:', err)
            }

            // console.log('dbUser:', dbUser, dbUser.data.role);
            navigate('/');
          } else {
            toast.error(
              'You must register and verify your email, access denied!'
            );
            sendEmailVerification(auth.currentUser).then(() => {
              // console.log('VERIFICATION EAMAIL SENT')
              navigate('/');
            });
            navigate('/');
          }
        })
        .catch((error) => {
          // console.log('LOGIN ERROR', error.message, error.code);
          toast.error(error.message);
          navigate('/login');
        });
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      setLoading(false);
    }
  };

  const loginForm = () => {
    return (
      <form className="login-page-form" onSubmit={handleSubmit}>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="enter your email"
          autoComplete="off"
          autoFocus
          style={{ marginTop: '10px', marginBottom: '5px' }}
        />
        <br />
        <input
          type="password"
          className="form-control"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="enter your password"
          autoComplete="off"
        />
        <br />
        <Link to="/forgot/password" className="login-card-forgot-password">
          Forgot Password
        </Link>
        <br />
        <Button
          icon={<MailOutlined />}
          block
          shape="round"
          size="large"
          className="login-button"
          style={{ marginTop: '20px' }}
          onClick={handleSubmit}
          disabled={!isValidEmailAddress() || password.length < 6}
        >
          Continue
        </Button>
        <br />
        <Button
          className="google-login-button"
          disabled={false}
          icon={<GoogleOutlined />}
          block
          shape="round"
          size="large"
          type="danger"
          style={{ width: '30vw', marginTop: '10px', marginBottom: '20px' }}
          onClick={googleLogin}
        >
          Login with Google
        </Button>
      </form>
    );
  };
  const isValidEmailAddress = () => {
    return !!email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/    // eslint-disable-line
    ); // eslint-disable-line
  };

  return (
    <div className="login-background">
      <div className="login-page">
        <div className="login-page-grid">
          <div className="login-page-card">
            <div className="login-card-logo">
              <img src={Logo} style={{ width: '200px' }} alt="lavenirai" />
            </div>
            <div className="login-page-item-1">
              {loading
                ? (
                <h2 className="text-warning">Loading..</h2>
                  )
                : (
                <div className="login-card-welcome">Welcome</div>
                  )}
            </div>
            <div className="login-page-form">
              {loginForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

// <form className="login-page-form d-flex flex-column justify-content-center" onSubmit={handleSubmit}>
