import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// IMAGEs
import StephanAtDesk from '../images/StephanAtDesk.png';
// REDUX
import { setUnityAppRunning, setCurrentPage } from '../redux/app.slice';
//APIs
import { getChapterGroupsApi } from '../apis/db.api';

const Base = () => {
  console.log('Base RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginStatus  } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line
  const { role } = useSelector((state) => state.user.user, shallowEqual);

  useEffect(() => {
    dispatch(setCurrentPage('home'));
  }, []);

  // useEffect(() => {
  //   console.log('----> GETTING CHAPTER GROUPS!!!')
  //   let groups = [];
  //   const fetchGroups = async () => {
  //     groups = await getChapterGroupsApi('123456', 'dev');
  //     console.log('----> CHAPTER GROUPS:', groups.data)
  //   }
  //   fetchGroups();
  // }, [])

  return (
    <div className="app-base-page">
      <div className="base-page home-page-background">
        {!loginStatus
          ? (
          <div className="base-grid">
            <div className="base-page-sect-1"></div>
            <input
              className="base-page-get-started-button"
              type="button"
              value="Get Started"
              onClick={() => navigate('/register')}
            />
            <div className="base-page-sect-2">
              ====ASSETS Tool====
            </div>
            <div className="base-page-sect-3">
              <img
                className="base-page-stephan-image"
                src={StephanAtDesk}
                alt="Stephan at desk"
              />
            </div>
          </div>
            )
          : (
          <div className="base-grid">
            {role && (
              <div className="base-page-app-nav-bling">
                <div
                  className="base-page-app-nav"
                  onClick={() => {
                    dispatch(setUnityAppRunning(true));
                    navigate('/manage/databases', { replace: true });
                  }}
                >
                  Databases
                </div>
              </div>
            )}
            {role && (
              <div className="base-page-insights-nav-bling">
                <div
                  className="base-page-insights-nav"
                  onClick={() => {
                    navigate(`/manage/assets`);
                  }}
                >
                  Assets
                </div>
              </div>
            )}
            {role && role === 'admin' && (
              <div className="base-page-documents-nav-bling">
                <div
                  className="base-page-documents-nav"
                  onClick={() => {
                    navigate('/documents');
                  }}
                >
                  Documents
                </div>
              </div>
            )}
            {role && role === 'admin' && (
              <div className="base-page-admin-nav-bling">
                <div
                  className="base-page-admin-nav"
                  onClick={() => {
                    navigate('/administrator');
                  }}
                >
                  Administrator
                </div>
              </div>
            )}
          </div>
            )}
      </div>
    </div>
  );
};

export default Base;
