import React, { useEffect ,useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Select from 'react-select'

import { getChaptersApi, updateChapterGroupsApi } from '../apis/db.api';

const Databases = () => {
    const [ selectedDatabase, setSelectedDatabase ] = useState(null);
    const [ chapters, setChapters ] = useState([]);
    const [ chapterGroup, setChapterGroup ] = useState([]);
    const { loginStatus  } = useSelector((state) => state.user, shallowEqual); // eslint-disable-line
    const { idToken } = useSelector((state) => state.user.user.idToken, shallowEqual);
  
    useEffect(() => {
      getChaptersFromDb(); 
    }, [ idToken ])

    const getChaptersFromDb = async () => {
      const chaptersRes = await getChaptersApi(idToken);
      console.log('---> dbChapters:', chaptersRes)
      const options = chaptersRes.data.map((c) => {
        return { value: c._id, label: c.title }
      })
      setChapters(options);
      return chaptersRes.data;
    }

    const updateChapterGroup = (option) => {
      setChapterGroup(option);
    }

    const addChapterGroup = async () => {
      // updateChapterGroupsApi(idToken, chapterGroup)
    }

    
    return (
        <div className="app-database-page">
        <div className="administrator-page">
          <div className="administrator-page-grid">
            <div className="administrator-page-sect-1">
              <div className="administrator-page-header">
                <div className="administrator-page-label">
                  Select, Save, Move, Restore and Copy Databases
                </div>
              </div>
            </div>

            <div className="administrator-page-section-1-left">
              <div className="administrator-section-1-left-grid">
                <div className="administrator-section-1-left-grid-row-1">
                  Database A
                </div>
                <div className="administrator-section-1-left-grid-row-2to5">
                <select
                    defaultValue={selectedDatabase}
                    onChange={(e) => setSelectedDatabase(e.target.value)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                  </select>
                </div>
                <div className="administrator-section-1-left-grid-row-5">
                  <button onClick={console.log('click')}>Select Database A</button>
                </div>
              </div>
            </div>

            <div className="administrator-page-section-1-mid">
              <ul>
                <li>Existing:</li>
                
              </ul>
            </div>

            <div className="administrator-page-section-1-right">
              <div className="administrator-section-1-right-grid">
                <div className="administrator-section-1-right-grid-row-1">
                  Database B
                </div>
                <div className="administrator-section-1-right-grid-row-2to5">
                <select
                    defaultValue={selectedDatabase}
                    onChange={(e) => setSelectedDatabase(e.target.value)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                  </select>
                </div>
                <div className="administrator-section-1-right-grid-row-5">
                  <button onClick={() => console.log('click')}>Select Database B</button>
                </div>
              </div>
            </div>

            <div className="administrator-page-section-1-far-right">
              <ul>
                <li>User Cache:</li>
                
              </ul>
            </div>

            {true && <div className="database-page-sect-3">
              <div className="administrator-page-header">
                <div className="administrator-page-label">
                Create Chapter Groups
                </div>
              </div>
            </div>}
            {true && <div className="database-page-section-3-left">
              <div className="administrator-section-1-left-grid">
                <div className="database-section-3-left-grid-row-1">
                  Select Chapters
                </div>
                <div className="database-section-1-left-grid-row-2to5">
                  {false && chapters && chapters.map((c) => {
                    return <div key={c._id}>{c.title}</div>
                  })}
                  {false && JSON.stringify(chapters)}
                  {true && <Select isMulti menuIsOpen={true} maxMenuHeight={100} options={chapters} onChange={(option) => updateChapterGroup(option)} />}
                </div>
                <div className="database-section-1-left-grid-row-5">
                  <Select options={[{label: 'development', value: 'dev'}, {label: 'staging', value: 'stage'}, {label: 'production', value: 'prod-I'}]}/>
                  <button onClick={() => console.log('click')}>Load Chapters</button>
                </div>
              </div>
            </div>}

            {true && <div className="database-page-section-3-right">
              {(
                <div className="administrator-section-1-right-grid">
                  <div className="database-section-1-right-grid-row-1">
                    Create Chapter Groups
                  </div>
                  
                  <div className="database-section-1-right-grid-row-2to5">
                  <input
                    type="text"
                    placeholder="Chapter Group Name"
                    onChange={(e) => console.log(e.target.value)}
                  />
                  {true && <Select menuIsOpen={true} options={chapterGroup} />}
                  </div>
                  <div className="database-section-1-right-grid-row-5">
                    <button onClick={() => console.log('click')}>Create Chapter Group</button>
                  </div>
                </div>
              )}
            </div>}

            {false && <div className="administrator-page-sect-5">
              <div className="administrator-page-header">
                <div className="administrator-page-label">App Settings</div>
              </div>
            </div>}
            {false && <div className="administrator-page-section-5-left">
              <div className="administrator-section-1-left-grid">
                <div className="administrator-section-1-left-grid-row-1">
                  Select a Database
                </div>
                <div className="administrator-section-1-left-grid-row-2to5">
                  
                </div>
                <div className="administrator-section-1-left-grid-row-5">
                  <button onClick={()=>console.log("click")}>Get Database</button>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    );
};

export default Databases;

// {organizationArray &&
//     organizationArray.length > 0 &&
//     organizationArray.map((o) => {
//       return <li key={o.name}>{o.name}</li>;
//     })}