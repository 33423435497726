import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

import { auth } from '../config/fb';
// REDUX
import {
  resetUser,
  setLoginStatus,
  setEmailVerified
} from '../redux/user.slice';
import {
  setMode,
  setSentTokenMessage,
  unloadUnityApp
} from '../redux/app.slice';
// API
import { getServerSession } from '../apis/admin.api';
import { getCurrentUser, deleteSession } from '../apis/user.api';
// Images
import Logo from '../images/lavenir-logo.png';

const UserNav = () => {
  console.log('UserNav RENDERING');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [isPlaying, setIsPlaying] = useState(true); // eslint-disable-line
  const [organization, setOrganization] = useState('');
  const { app, user } = useSelector((state) => ({ ...state })); // eslint-disable-line

  useEffect(() => {
    const setUserInfo = async () => {
      // console.log('VERIFIED, NAME, ORG:', user.user.emailVerified, user.user.name, user.user.organization )
      await setName(user.user.name);
      if (user.user.organization.name) {
        await setOrganization(user.user.organization.name);
      } else {
        await setOrganization(user.user.organization);
      }
    };

    setTimeout(() => {
      if (user.user.name !== '' && user.user.organization !== '') {
        setUserInfo();
      }
    }, 100);
  }, [user]);
  // }, [user.loginStatus, user.user.name, user.user.organization])

  const handleClickOverlay = () => {
    // console.log('OVERLAY CLICK')
    // setSentMessage(true);
    if (!app.sentTokenMessage) {
      // handleUnload();
      // setIsPlaying(false);
      // dispatch(setSentTokenMessage(false));
      // toast('The LavenirAI application is now unloaded, navigation re-enabled.')
      // navigate('/');
      // console.log('OVERLAY BLOCK')
    } else {
      // console.log('OVERLAY PASS')
    }
  };

  const logOutHandler = () => {
    signOut(auth)
      .then(async (res) => {
        // Sign-out successful
        await dispatch(setLoginStatus(false));
        await dispatch(setEmailVerified(false));
        await dispatch(resetUser());
        await deleteSession();

        // TBD clear user session
        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 0);
        // }, 300);
      })
      .catch((error) => {
        app.reactVerbosity > 0 && console.log('ERROR SIGNING OUT:', error);
      });
  };

  const getCurrentUSerfromSession = async () => {
    const curUser = await getCurrentUser(user.user.idToken); // eslint-disable-line
    // console.log('CURRENT USER -->', curUser);
  };

  const testSessionGet = async () => {
    const result = await getServerSession();  // eslint-disable-line
    // console.log('TEST SERVER-USER SESSION GET:', result);
  };

  const handleMode = async () => {
    if (app.mode === 'dev') {
      await dispatch(setMode('prod'));
    } else if (app.mode === 'prod') {
      await dispatch(setMode('dev'));
    }
  };
 

  const handleLogoNav = () => {
    navigate('/', { replace: true });
  };
  // console.log("user.user.organization:", user.user.organization)
  return (
    <div>
      <div className="">
        <div className="header">
          <div className="header-grid">
            {false && (
              <input
                className="header-mode-checkbox"
                type="checkbox"
                onChange={handleMode}
              />
            )}
            {app.currentPage === 'unityApp' && !app.sentTokenMessage && (
              <div
                className="header-page-nav-overlay"
                style={{
                  backgroundColor: !app.sentTokenMessage ? 'red' : 'blue'
                }}
                onClick={handleClickOverlay}
              ></div>
            )}

            <div className="header-logo" onClick={handleLogoNav}>
              <img src={Logo} style={{ width: '200px' }} alt="lavenirai" />
            </div>
            
            <div className="header-page-title">Asset Server</div>
            <div className="header-item-1">
              
            </div>
            {true && user && !user.loginStatus && (
              <div className="header-item-2">
                <Link className="nav-link" to="/register">
                  Register
                </Link>
              </div>
            )}
            {user && !user.loginStatus && (
              <div className="header-item-3">
                <Link className="nav-link" to="/login">
                  Login
                </Link>
              </div>
            )}
            {app.currentPage !== 'unityApp' && user && user.loginStatus && (
              <div className="header-item-4">
                <button className="logout-button" onClick={logOutHandler}>
                  LogOut
                </button>
              </div>
            )}
            {false && user && user.loginStatus && (
              <div
                className="header-item-5"
                style={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: '10px',
                  color: '#141414'
                }}
              >
                <button className="logout-button" onClick={testSessionGet}>
                  CTR
                </button>
              </div>
            )}
            {false && user && user.loginStatus && (
              <div
                className="header-item-6"
                style={{
                  backgroundColor: '#f5f5f5',
                  borderRadius: '10px',
                  color: '#141414'
                }}
              >
                <button
                  className="logout-button"
                  onClick={getCurrentUSerfromSession}
                >
                  CurUser
                </button>
              </div>
            )}

            <div className="header-verified">
              {user.user.emailVerified && name !== ''
                ? (
                <p>
                  {name}&nbsp;-&nbsp;{organization}
                </p>
                  )
                : (
                <p style={{ color: 'red' }}>
                  user not verified {user.emailVerified}
                </p>
                  )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserNav);

// &nbsp;&nbsp;<button className="logout-button" onClick={revokeHandler}>Revoke</button>
