import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import WebFont from 'webfontloader';

// FB
import { auth } from './config/fb';
// APIs
import { fetchAppVerbosityApi } from './apis/app.api';
import { verifyUser, updateSession } from './apis/admin.api';
import { reloadSession, updateCurrentUserTokens } from './apis/user.api';
// REDUX
import { setTriedToReload, setPrivacy, setAppVerbosity } from './redux/app.slice';
import {
  setFbUser,                // eslint-disable-line
  setUser,
  setName,
  setRole,
  setOrganization,
  setLoginStatus,
  setIdToken
} from "./redux/user.slice"; // eslint-disable-line
// COMPONENTS
import UserNav from './components/UserNav';
// PAGES
import Base from './pages/Base';
import Login from './pages/Login';
import Assets from './pages/Assets';
import Databases from './pages/Databases';

function App() {
  const dispatch = useDispatch();
  const { app, user } = useSelector((state) => ({ ...state })); // eslint-disable-line
  const triedToReload = app.triedToReload;
  
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins', 'Montserrat']
      }
    });
  }, []);
  useEffect(() => {
    setInterval(async () => {
      // check to see that accessToken has not expired
      await checkToSeeIfSessionExpired();
      // console.log('INTERVAL TIMESTAMP:', new Date())
    }, 5000);
  }, []);
  const checkToSeeIfSessionExpired = async () => {
    try {
      const userVerification = await verifyUser();
      if (
        userVerification.data.cookie !== 'expired' &&
        parseInt(userVerification.data.expiresIn) <= 5
      ) {
        auth.currentUser
          .getIdToken(true)
          .then(async function (idToken) {
            const response = await updateSession(idToken); // eslint-disable-line
            try {
              const dbUser = await updateCurrentUserTokens(idToken, user.user.email); // eslint-disable-line
            } catch (err) {
              // TBD remove log
              // console.log('updateSession, reloadSesion err:', err)
            } // reloadSession
          })
          .catch(function (error) {  // eslint-disable-line
            // console.log('===> REFRESH TOKEN ERROR:', error)
          });
      }
    } catch (err) {
    }
  };
  useEffect(() => {
    // this reloadSession version does not revert to teh AutoLogin page
    const reload = () => {
      setTimeout(async () => {
        // console.log('RELOAD SESSION', triedToReload)
        if (!triedToReload) {
          try {
            reloadSession().then(async (res) => {
              if (res.data.error) {
                // console.log('APP RELOAD SESSION ERROR:', res.data.error)
                await window.history.pushState({}, '', '/login');
              } else {
                // console.log('APP RELOAD SESSION SUCCESS', res.data)
                await dispatch(setTriedToReload(true));
                if (res.data !== '') {
                  const user = res.data;
                  // console.log('--------------> refresh user:', user);
                  const idTokenResult = user.accessToken;
                  user.idToken = idTokenResult;
                  const verbosity = await fetchAppVerbosityApi(idTokenResult);
                  await dispatch(setAppVerbosity(verbosity.data));
                  await dispatch(setIdToken(idTokenResult));
                  await dispatch(setUser(JSON.stringify(user)));
                  // await dispatch(setFbUser(JSON.stringify(user)));
                  await dispatch(setLoginStatus(true));
                  await dispatch(setRole(user.role));
                  await dispatch(setName(user.name));
                  await dispatch(setOrganization(user.organization));
                  await dispatch(setPrivacy(user.organization.privacy));
                }
              }
            });
          } catch (err) {
            // TBD remove log
            // console.log(err)
          }
        }
      }, 300);
    };
    reload();
  }, []); // eslint-disable-line


  return (
    <div className="app-container">
      
      <Router>
      <div className="app-grid">
        <header className='app-header'>
          <Header/>
        </header>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Base />} />
          <Route path="/login" exact element={<Login />} />
          <Route path='/manage/assets' element={<Assets/>}/>
          <Route path='/manage/databases' element={<Databases/>}/>
          <Route path="*" element={<Base />} />
        </Routes>
      </div>
      </Router>
      
    </div>
  );

  function Header() {
    // visible on every page
    return (
      <div className="app-header">
        <UserNav />
      </div>
    );
  }
}


export default App;
