import { configureStore } from '@reduxjs/toolkit';
// REDUCERS
import appSlice from './app.slice';
import userSlice from './user.slice';

const store = configureStore({
  reducer: {
    app: appSlice,
    user: userSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
  }
});

export default store;
