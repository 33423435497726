import axios from 'axios';

export const getUnityModuleCount = async () => {
  // /modules/count
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const response = await axiosInstance.get(
    process.env.REACT_APP_API + '/modules/count'
  );
  // console.log('===> API MODULE COUNT:', response)
  return response;
};

export const reloadSession = async () => {
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.get(
      process.env.REACT_APP_API + '/user/reload/session'
    );
    // console.log('-----------> /user/reload/session RESPONSE:', response)
    resolve(response);
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

export const deleteSession = async () => {
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    try {
      const response = axiosInstance.get(
        process.env.REACT_APP_API + '/user/delete/session'
      );
      // console.log('-----------> /user/delete/session RESPONSE:', response);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

export const setCurrentUser = async (authtoken, user, email) => {
  // console.log('----------> setCurrentUser authtoken:', authtoken)
  // console.log('-----> setCurrentUser user:', user)
  const config = {
    headers: {
      authtoken
      // user
    }
  };
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/user/set/current',
      { user, email },
      config
    );
    // console.log('-----------> setCurrentUser RESPONSE:', response)
    resolve(response);
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

export const updateCurrentUserTokens = async (idToken, email) => {
  const promise = await new Promise((resolve, reject) => {
    const axiosInstance = axios.create({
      withCredentials: true
    });
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/user/update/token',
      { idToken, email },
      {
        headers: {
          authtoken: idToken
        }
      }
    );
    // console.log('-----------> setCurrentUser RESPONSE:', response)
    resolve(response);
  });
  // console.log('updateCurrentUserTokens ----------> PROMISE:', promise)
  return promise;
};

export const getCurrentUser = async (authtoken) => {
  // console.log('----------> getCurrentUser authtoken:', authtoken)
  const config = {
    headers: {
      authtoken
    }
  };
  const promise = await new Promise((resolve, reject) => {
    // let response = await axios.post(process.env.REACT_APP_API + `/set-current-user?user=${user}`,
    const response = axios.post(
      process.env.REACT_APP_API + '/user/get',
      config
    );
    // console.log('-----------> /user/get RESPONSE:', response)
    resolve(response);
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

export const getUser = async (authtoken, email) => {
  // console.log(`calling get-user AUTHTOKEN: `, authtoken)
  const promise = await new Promise((resolve, reject) => {
    const response = axios.get(
      process.env.REACT_APP_API + `/get-user?email=${email}`,
      {
        headers: {
          authtoken
        }
      }
    );
    // console.log('RESPONSE:', response)
    resolve(response);
  });
  // console.log('PROMISE:', promise)
  return promise;
};

export const createUser = async (authtoken, user, org) => {
  // console.log(`calling get-user AUTHTOKEN: `, authtoken)
  // console.log(`===> calling get-user USER: `, user)
  return await axios.post(
    process.env.REACT_APP_API + '/create-user',
    {
      user,
      org
    },
    {
      headers: {
        authtoken
      }
    }
  );
};

// export const getUserSessionData = async (authtoken,user, selection) => {
//   // console.log('----------> setCurrentUser authtoken:', authtoken)
//   let config = {
//     headers: {
//       authtoken
//     }
//   }
//   const promise = await new Promise(async (resolve, reject) => {
//     let response = await axios.post(process.env.REACT_APP_API + `/user/data`,
//       { user, selection }, config
//       )
//       // console.log('-----------> /user/data server RESPONSE:', response)
//       resolve(response);
// })
// // console.log('----------> PROMISE:', promise)
// return promise;
// }

export const getUnitySessionData = async (authtoken, user, selection) => {
  // console.log('----------> setCurrentUser authtoken:', authtoken)
  const axiosInstance = axios.create({
    withCredentials: true
  });
  const config = {
    headers: {
      authtoken
    }
  };
  const promise = await new Promise((resolve, reject) => {
    const response = axiosInstance.post(
      process.env.REACT_APP_API + '/unity/user/data',
      { user, selection },
      config
    );
    // console.log('-----------> /user/data server RESPONSE:', response)
    resolve(response);
  });
  // console.log('----------> PROMISE:', promise)
  return promise;
};

export const queueUser = async (authtoken, user) => {
  // console.log(`calling get-user AUTHTOKEN: `, authtoken)
  // console.log(`===> calling get-user USER: `, user)
  return await axios.post(
    process.env.REACT_APP_API + '/user/queue',
    {
      user
    },
    {
      headers: {
        authtoken
      }
    }
  );
};
